/**
 * Created by leo108 on 2019/10/20.
 */

import axios from 'axios'
import * as Sentry from '@sentry/browser'
window.axios = axios

function configHeader () {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': Spark.csrfToken
  }
  if (Spark.state && Spark.state.user && Spark.state.user.web_api_key) {
    headers.Authorization = `ApiKey ${Spark.state.user.web_api_key}`
  }
  if (Spark.kioskImpersonatorToken) {
    headers['kiosk-impersonator-token'] = Spark.kioskImpersonatorToken
  }

  window.axios.defaults.headers.common = headers
}
configHeader()

window.axios.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  if (!error.response) {
    return Promise.reject(error)
  }

  switch (error.response.status) {
    case 401:
      window.SwalTimer.fire('You were logged out', '', 'error').then(() => {
        location.href = Spark.router('login')
      })
      break
    case 403:
      if (error.response.data) {
        await handleForbidden(error.response.data)
      }
      break
    case 404:
      window.SwalAlert.fire('Resource not found', 'Please reload and try again', 'error')
      break
    case 413:
      window.SwalAlert.fire('The file is too large', 'Please use a smaller one', 'error')
      break
    case 419:
      window.SwalAlert.fire('The page was expired', 'Please reload and try again', 'error')
      break
    case 422:
      // ignore
      break
    case 429:
      handleTooManyRequests(error)
      break
    case 500:
    case 502:
    case 503:
      window.SwalAlert.fire('Server error', '', 'error')
      break
    case 504:
    case 524:
      handleTimeout(error)
      break
    case 520:
      window.SwalAlert.fire('Dubb server is busy now', 'Please try again later', 'error')
      break
    default:
      if (error.code === 'ERR_NETWORK') {
        break
      }

      Sentry.withScope(scope => {
        scope
          .setExtra('error', error)
          .setExtra('response', error.response)
          .setExtra('request', {
            method: error.response.config?.method,
            url: error.response.config?.url
          })
          .setFingerprint(['unknowns-status', error.response.status])
          .setLevel('warning')
        Sentry.captureMessage(`Unknown status code: ${error.response.status}`)
      })
      break
  }

  return Promise.reject(error)
})

async function handleForbidden (resp) {
  if (resp instanceof Blob) {
    resp = JSON.parse(await resp.text())
  }

  if (resp.class) {
    switch (resp.class) {
      case 'FeatureLockedException':
        window.SwalConfirm.fire({
          icon: 'error',
          title: resp.message,
          text: resp.data.text,
          showLoaderOnConfirm: false,
          confirmButtonText: 'Subscribe'
        }).then(res => {
          if (res.isConfirmed) {
            window.open(resp.data.subscribeUrl)
          }
        })
        break
      case 'QuotaExceedException':
        window.SwalConfirm.fire({
          icon: 'error',
          title: resp.message,
          showLoaderOnConfirm: false,
          confirmButtonText: resp.data.dialog.confirm_btn,
          cancelButtonText: resp.data.dialog.cancel_btn,
          showCancelButton: resp.data.dialog.confirm_url !== null
        }).then(res => {
          if (res.isConfirmed && resp.data.dialog.confirm_url !== null) {
            window.open(resp.data.dialog.confirm_url)
          }
        })
        break
      case 'MissingTeamException':
        window.SwalConfirm.fire({
          icon: 'info',
          title: 'Add Your Company Info',
          text: 'In order to view this section, you must add your company name to your account. Don\'t worry, you can always change it later if you need to.',
          showLoaderOnConfirm: false,
          confirmButtonText: 'ADD COMPANY INFO',
          cancelButtonText: 'NOT YET'
        }).then(res => {
          if (res.isConfirmed) {
            window.open(Spark.router('web.subdomain'))
          }
        })
        break
      case 'NoRolePermissionException':
        window.SwalAlert.fire({
          icon: 'warning',
          title: resp.message,
          text: resp.data.text
        })
        break
      default:
        window.SwalAlert.fire(resp.message ? resp.message : 'You have no permission to perform this action', '', 'error')
        break
    }
  } else {
    window.SwalAlert.fire(resp.message ? resp.message : 'There is something wrong', '', 'error')
  }
}

function handleTooManyRequests (error) {
  let message = 'Apologies. Please try it again later.'

  if (typeof error.response.headers['x-ratelimit-reset'] !== 'undefined') {
    const diff = error.response.headers['x-ratelimit-reset'] - new Date().getTime() / 1000
    const minutes = Math.max(1, Math.floor((diff / 1000) / 60))
    message = `Apologies. Please try it again in ${minutes} minute${minutes > 1 ? 's' : ''}.`
  }

  window.SwalAlert.fire({
    iconHtml: '<span class="fad fa-umbrella"></span>',
    iconColor: '#3699FF',
    title: 'We are experiencing high traffic',
    text: message
  })
}

function handleTimeout (error) {
  Sentry.withScope(scope => {
    scope.setExtra('response', error.response)
      .setExtra('request', {
        method: error.response.config?.method,
        url: error.response.config?.url
      })
      .setFingerprint(['api-timeout'])
      .setLevel('warning')
    Sentry.captureMessage('API request timeout')
  })
  window.SwalAlert.fire('Dubb server is busy now', 'Please try again later', 'error')
}

window.Bus.$on('login-user', () => {
  configHeader()
})
