const constants = require('../generated/constants')

export default [{
  path: '/dashboard',
  meta: {
    group: 'dashboard'
  },
  components: {
    default: () => import('../modules/dashboard-v5/empty.vue'),
    'nav-right-end': () => import('../modules/dashboard-v5/sub-views/quick-panel-toggle'),
    'quick-panel': () => import('../modules/dashboard-v5/sub-views/quick-sidebar.vue'),
    'mobile-header': () => import('../modules/dashboard-v5/sub-views/mobile-header'),
    aside: () => import('../modules/dashboard-v5/sub-views/aside'),
    'aside-footer': () => import('../modules/dashboard-v5/sub-views/aside-footer')
  },
  children: [
    {
      path: '/',
      name: 'dashboard.home',
      component: () => import('../modules/dashboard-v5/home'),
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: 'ai-assistant',
      name: 'dashboard.ai-assistant',
      component: () => import('../modules/dashboard-v5/ai-assistant'),
      meta: {
        title: 'AI Assistant'
      }
    },
    {
      path: 'videos',
      name: 'dashboard.videos',
      component: () => import('../modules/dashboard-v5/videos/list'),
      meta: {
        title: 'Videos'
      }
    },
    {
      path: 'video-replies',
      name: 'dashboard.video-replies',
      component: () => import('../modules/dashboard-v5/videos/video-replies'),
      meta: {
        title: 'Video Replies'
      }
    },
    {
      path: 'contacts',
      name: 'dashboard.contacts.index',
      component: () => import('../modules/dashboard-v5/contacts/index'),
      meta: {
        title: 'Contacts'
      }
    },
    {
      path: 'contacts/:id',
      name: 'dashboard.contacts.show',
      component: () => import('../modules/dashboard-v5/contacts/show'),
      meta: {
        title: 'Contact Details',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'deals/board',
      name: 'dashboard.deals.index',
      component: () => import('../modules/dashboard-v5/deals/index'),
      meta: {
        title: 'Deals',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_DEAL
      }
    },
    {
      path: 'deals/board/:id',
      name: 'dashboard.deals.show',
      component: () => import('../modules/dashboard-v5/deals/index'),
      meta: {
        rolePermission: constants.App_Models_TeamRole.PERMISSION_DEAL
      }
    },
    {
      path: 'deals/list',
      name: 'dashboard.deals.list',
      component: () => import('../modules/dashboard-v5/deals/list'),
      meta: {
        title: 'Deals',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_DEAL
      }
    },
    {
      path: 'tasks',
      name: 'dashboard.tasks.index',
      component: () => import('../modules/dashboard-v5/tasks/index'),
      meta: {
        title: 'Tasks',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_TASK
      }
    },
    {
      path: 'forms',
      name: 'dashboard.forms.index',
      component: () => import('../modules/dashboard-v5/forms/index'),
      meta: {
        title: 'Forms',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_FORM
      }
    },
    {
      path: 'forms/create',
      name: 'dashboard.forms.create',
      component: () => import('../modules/dashboard-v5/forms/form'),
      meta: {
        title: 'Create New Form',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_FORM
      }
    },
    {
      path: 'forms/:id',
      name: 'dashboard.forms.edit',
      component: () => import('../modules/dashboard-v5/forms/form'),
      meta: {
        title: 'Edit Form',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_FORM
      }
    },
    {
      path: 'forms/:id/clone',
      name: 'dashboard.forms.clone',
      component: () => import('../modules/dashboard-v5/forms/form'),
      meta: {
        title: 'Clone Form',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_FORM
      }
    },
    {
      path: 'scripts',
      name: 'dashboard.scripts.index',
      component: () => import('../modules/dashboard-v5/scripts/index'),
      meta: {
        title: 'Scripts'
      }
    },
    {
      path: 'scripts/create',
      name: 'dashboard.scripts.create',
      component: () => import('../modules/dashboard-v5/scripts/form'),
      meta: {
        title: 'Create New Script',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'scripts/:hash_id',
      name: 'dashboard.scripts.edit',
      component: () => import('../modules/dashboard-v5/scripts/form'),
      meta: {
        title: 'Edit Script',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'video-presets',
      name: 'dashboard.video-presets.index',
      component: () => import('../modules/dashboard-v5/video-presets/index'),
      meta: {
        title: 'Video Presets'
      }
    },
    {
      path: 'video-presets/create',
      name: 'dashboard.video-presets.create',
      component: () => import('../modules/dashboard-v5/video-presets/form'),
      meta: {
        title: 'Create New Video Preset',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'video-presets/:id/edit',
      name: 'dashboard.video-presets.edit',
      component: () => import('../modules/dashboard-v5/video-presets/form'),
      meta: {
        title: 'Edit Video Preset',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'video-presets/:id/clone',
      name: 'dashboard.video-presets.clone',
      component: () => import('../modules/dashboard-v5/video-presets/form'),
      meta: {
        title: 'Clone Video Preset',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'showcases',
      name: 'dashboard.showcases.index',
      component: () => import('../modules/dashboard-v5/showcases/index'),
      meta: {
        title: 'Showcases'
      }
    },
    {
      path: 'showcases/create',
      name: 'dashboard.showcases.create',
      component: () => import('../modules/dashboard-v5/showcases/form'),
      meta: {
        title: 'Create New Showcase',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'showcases/:hash_id/edit',
      name: 'dashboard.showcases.edit',
      component: () => import('../modules/dashboard-v5/showcases/form'),
      meta: {
        title: 'Edit Showcase',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'showcases/:hash_id/clone',
      name: 'dashboard.showcases.clone',
      component: () => import('../modules/dashboard-v5/showcases/form'),
      meta: {
        title: 'Clone Showcase',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'companies',
      name: 'dashboard.companies',
      component: () => import('../modules/dashboard-v5/companies'),
      meta: {
        title: 'Companies'
      }
    },
    {
      path: 'reporting',
      name: 'dashboard.reporting',
      component: () => import('../modules/dashboard-v5/reporting'),
      meta: {
        title: 'Reporting'
      }
    },
    {
      path: 'calls-to-action',
      name: 'dashboard.call-to-actions.index',
      component: () => import('../modules/dashboard-v5/call-to-actions/index'),
      meta: {
        title: 'Call To Actions'
      }
    },
    {
      path: 'calls-to-action/:id/edit',
      name: 'dashboard.call-to-actions.edit',
      component: () => import('../modules/dashboard-v5/call-to-actions/form'),
      meta: {
        title: 'Edit Call To Action',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'calls-to-action/create',
      name: 'dashboard.call-to-actions.create',
      component: () => import('../modules/dashboard-v5/call-to-actions/form'),
      meta: {
        title: 'Create New Call To Action',
        bodyClass: 'subheader-enabled subheader-fixed'
      }
    },
    {
      path: 'landing-pages',
      name: 'dashboard.landing-pages.index',
      component: () => import('../modules/dashboard-v5/landing-pages/index.vue'),
      meta: {
        title: 'Landing Pages'
      }
    },
    {
      path: 'campaigns/email',
      name: 'dashboard.campaigns.email.index',
      component: () => import('../modules/dashboard-v5/campaigns/email/list'),
      meta: {
        title: 'Email Campaigns',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/email/create',
      name: 'dashboard.campaigns.email.create',
      component: () => import('../modules/dashboard-v5/campaigns/email/create'),
      meta: {
        title: 'Create New Email Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/email/:slug/edit/:step?',
      name: 'dashboard.campaigns.email.edit',
      component: () => import('../modules/dashboard-v5/campaigns/email/create'),
      meta: {
        title: 'Edit Email Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/email/:slug/clone',
      name: 'dashboard.campaigns.email.clone',
      component: () => import('../modules/dashboard-v5/campaigns/email/create'),
      meta: {
        title: 'Clone Email Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/sms',
      name: 'dashboard.campaigns.sms.index',
      component: () => import('../modules/dashboard-v5/campaigns/sms/list'),
      meta: {
        title: 'SMS Campaigns',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/sms/create',
      name: 'dashboard.campaigns.sms.create',
      component: () => import('../modules/dashboard-v5/campaigns/sms/create'),
      meta: {
        title: 'Create New SMS Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/sms/:slug/edit/:step?',
      name: 'dashboard.campaigns.sms.edit',
      component: () => import('../modules/dashboard-v5/campaigns/sms/create'),
      meta: {
        title: 'Edit SMS Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'campaigns/sms/:slug/clone',
      name: 'dashboard.campaigns.sms.clone',
      component: () => import('../modules/dashboard-v5/campaigns/sms/create'),
      meta: {
        title: 'Clone SMS Campaign',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_CAMPAIGN
      }
    },
    {
      path: 'workflow',
      name: 'dashboard.workflow.index',
      component: () => import('../modules/dashboard-v5/automation/workflow/list'),
      meta: {
        title: 'Automations',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'workflow/create',
      name: 'dashboard.workflow.create',
      component: () => import('../modules/dashboard-v5/automation/workflow/create'),
      meta: {
        title: 'Create New Automation',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'workflow/:slug/edit/:step?',
      name: 'dashboard.workflow.edit',
      component: () => import('../modules/dashboard-v5/automation/workflow/create'),
      meta: {
        title: 'Edit Automation',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'workflow/:slug',
      name: 'dashboard.workflow.view',
      component: () => import('../modules/dashboard-v5/automation/workflow/view'),
      meta: {
        title: 'Automation Results',
        bodyClass: 'subheader-enabled subheader-fixed',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'automation/campaigns/email',
      name: 'dashboard.automation.campaigns.email.index',
      component: () => import('../modules/dashboard-v5/automation/campaigns/email-list'),
      meta: {
        title: 'Automation Email Campaigns',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'automation/campaigns/sms',
      name: 'dashboard.automation.campaigns.sms.index',
      component: () => import('../modules/dashboard-v5/automation/campaigns/sms-list'),
      meta: {
        title: 'Automation SMS Campaigns',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_WORKFLOW
      }
    },
    {
      path: 'email-templates',
      name: 'dashboard.email-templates.index',
      component: () => import('../modules/dashboard-v5/email-templates/index'),
      meta: {
        title: 'Email Templates',
        rolePermission: constants.App_Models_TeamRole.PERMISSION_EMAIL_TEMPLATE
      }
    },
    {
      path: 'global-search',
      name: 'dashboard.global-search',
      component: () => import('../modules/dashboard-v5/global-search/index'),
      meta: {
        title: 'Global Search'
      }
    }
  ]
}]
