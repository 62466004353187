/*
 |--------------------------------------------------------------------------
 | Laravel Spark Components
 |--------------------------------------------------------------------------
 |
 | Here we will load the Spark components which makes up the core client
 | application. This is also a convenient spot for you to load all of
 | your components that you write while building your applications.
 */
import Vue from 'vue'

Vue.component('AuthSocialNav', () => import('./auth/social-nav'))
Vue.component('AuthRegister', () => import('./auth/register/register.vue'))
Vue.component('MessageBubble', () => import('./message-bubble.vue'))
Vue.component('QuickConversation', () => import('./quick-conversation'))

Vue.component('VideoShow', () => import('./video/show.vue'))
Vue.component('VideoShowReply', () => import('./video/show-reply.vue'))
Vue.component('VideoAppShare', () => import('./video/app-share.vue'))
Vue.component('VideoEmbed', () => import('./video/embed.vue'))
Vue.component('VideoEmbedCreateWrapper', () => import('./video/embed-create-wrapper'))
Vue.component('VideoCreateWithPermissionModal', () => import('./video/create-with-permission-modal'))
Vue.component('VideoPasswordForm', () => import('./video/password-form.vue'))
Vue.component('VideoPortfolio', () => import('./video/portfolio'))

Vue.component('ShowcaseShow', () => import('./showcase/show'))
Vue.component('ShowcaseEmbed', () => import('./showcase/embed'))

Vue.component('FormRender', () => import('./form/render'))
Vue.component('BootModal', () => import('./common/boot-modal'))
Vue.component('ForceRouterLink', () => import('./common/force-router-link'))
Vue.component('VideoJs', () => import('./common/video-js.vue'))
Vue.component('VideoPreviewList', () => import('./common/video-preview-list'))
Vue.component('WorldMapJoin', () => import('./common/world-map-join'))
Vue.component('FeatureLocker', () => import('./common/feature-locker'))
Vue.component('ExitIntent', () => import('./common/exit-intent'))
Vue.component('EmptyTableMessage', () => import('./common/empty-table-message'))
Vue.component('Paginator', () => import('./common/paginator.vue'))
Vue.component('V4Navbar', () => import('./common/v4-navbar'))
Vue.component('RichTooltip', () => import('./rich-tooltip'))

Vue.component('LandingPageBuilderGrapesjs', () => import('./landing-page/grapesjs-builder'))
Vue.component('LandingPageBuilderUnlayer', () => import('./landing-page/unlayer-builder'))

Vue.component('GuestVideoCreate', () => import('./guest-video/create'))
Vue.component('GuestVideoShow', () => import('./guest-video/show'))
Vue.component('GuestVideoEdit', () => import('./guest-video/edit'))

Vue.component('EmailTemplateBuilderGrapesjs', () => import('./email-template/grapesjs-builder.vue'))
Vue.component('EmailTemplateBuilderUnlayer', () => import('./email-template/unlayer-builder.vue'))

Vue.component('VideoTemplateBuilder', () => import('./video-template/builder'))
Vue.component('VideoSection', () => import('./video-template/video-section'))
Vue.component('VideoTitle', () => import('./video-template/video-title'))
Vue.component('VideoDescription', () => import('./video-template/video-description'))
Vue.component('CtaSection', () => import('./video-template/cta-section'))
Vue.component('ContactSection', () => import('./video-template/contact-section'))
Vue.component('FormSection', () => import('./video-template/form-section'))
Vue.component('CalendarSection', () => import('./video-template/calendar-section'))

Vue.component('TeamSubscription', () => import('./team-subscription/index.vue'))

Vue.component('PagesMarketplace', () => import('./pages/marketplace'))
